import { LitElement, html, css } from 'lit';
import { AuthService } from './auth-service.js';

export class ProformanceApp extends LitElement {
  static properties = {
    messages: { type: Array },
    error: { type: String },
    user: { type: Object },
    isLoading: { type: Boolean },
    isLoggingOut: { type: Boolean },
  };

  static styles = css`
    :host {
      display: block;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
      color: var(--color-text-primary, #2e2e2e);
      background-color: var(--color-background, #fafafa);
      max-width: 800px;
      margin: 0 auto;
      padding: 2rem;
      box-sizing: border-box;
    }

    .container {
      background-color: var(--color-background-light, #ffffff);
      border-radius: 0.5rem;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      padding: 2rem;
    }

    h1 {
      color: var(--color-brand-primary, #6666c4);
      font-size: 2rem;
      margin-top: 0;
      margin-bottom: 1.5rem;
    }

    .input-group {
      display: flex;
      margin-bottom: 1.5rem;
    }

    input {
      flex-grow: 1;
      padding: 0.5rem;
      font-size: 1rem;
      border: 1px solid var(--color-border, #e0e0e0);
      border-radius: 0.25rem 0 0 0.25rem;
    }

    button {
      background-color: var(--color-brand-primary, #6666c4);
      color: white;
      border: none;
      padding: 0.5rem 1rem;
      font-size: 1rem;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }

    button:hover {
      background-color: var(--color-brand-primary-dark, #5151a3);
    }

    .add-button {
      border-radius: 0 0.25rem 0.25rem 0;
    }

    ul {
      list-style-type: none;
      padding: 0;
    }

    li {
      background-color: var(--color-background-light, #ffffff);
      border: 1px solid var(--color-border, #e0e0e0);
      border-radius: 0.25rem;
      margin-bottom: 0.5rem;
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .message-content {
      flex-grow: 1;
      margin-right: 1rem;
    }

    .button-group {
      display: flex;
      gap: 0.5rem;
    }

    .update-button {
      background-color: var(--color-success, #1aaa55);
    }

    .update-button:hover {
      background-color: var(--color-success-dark, #168f48);
    }

    .delete-button {
      background-color: var(--color-danger, #db3b21);
    }

    .delete-button:hover {
      background-color: var(--color-danger-dark, #b92d1b);
    }

    .error {
      color: var(--color-danger, #db3b21);
      background-color: var(--color-danger-light, #fbe9e7);
      border: 1px solid var(--color-danger, #db3b21);
      border-radius: 0.25rem;
      padding: 0.75rem;
      margin-bottom: 1rem;
    }

    .user-info {
      margin-bottom: 1rem;
    }

    .login-button, .logout-button {
      background-color: var(--color-brand-secondary, #4CAF50);
      color: white;
      border: none;
      padding: 0.5rem 1rem;
      font-size: 1rem;
      cursor: pointer;
      transition: background-color 0.3s ease;
      border-radius: 0.25rem;
    }

    .login-button:hover, .logout-button:hover {
      background-color: var(--color-brand-secondary-dark, #45a049);
    }

    .loading {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
    }
  `;

  constructor() {
    super();
    this.messages = [];
    this.error = '';
    this.user = null;
    this.isLoading = true;
    this.isLoggingOut = false;
    this.apiBaseUrl = import.meta.env.VITE_API_BASE_URL;
    this.authService = new AuthService();
  }

  connectedCallback() {
    super.connectedCallback();
    this.checkAuthStatus();

    // Check for login success or error in URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const loginStatus = urlParams.get('login');
    if (loginStatus === 'success') {
      this.checkAuthStatus();
    } else if (loginStatus === 'error') {
      this.error = 'Login failed. Please try again.';
    }

    // Remove the URL parameters
    window.history.replaceState({}, document.title, window.location.pathname);
  }

  async checkAuthStatus() {
    this.isLoading = true;
    try {
      const data = await this.authService.checkAuthStatus();
      if (data.authenticated) {
        this.user = data.user;
        this.fetchMessages();
      } else {
        this.user = null;
      }
    } catch (error) {
      console.error('Error checking auth status:', error);
      this.error = 'Failed to check authentication status.';
    } finally {
      this.isLoading = false;
    }
  }

  async login() {
    this.authService.login();
  }

  async logout() {
    this.isLoggingOut = true;
    try {
      await this.authService.logout();
    } catch (error) {
      console.error('Error logging out:', error);
      this.error = 'Failed to log out. Please try again.';
    } finally {
      this.isLoggingOut = false;
    }
  }

  async fetchMessages() {
    try {
      const response = await fetch(`${this.apiBaseUrl}/messages`, { credentials: 'include' });
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      this.messages = data;
      this.error = '';
    } catch (error) {
      console.error('Error fetching messages:', error);
      this.error = `Failed to fetch messages: ${error.message}`;
    }
  }

  async addMessage(content) {
    try {
      const response = await fetch(`${this.apiBaseUrl}/messages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ content }),
        credentials: 'include',
      });
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      await this.fetchMessages();
      this.error = '';
    } catch (error) {
      console.error('Error adding message:', error);
      this.error = `Failed to add message: ${error.message}`;
    }
  }

  async updateMessage(id, content) {
    try {
      const response = await fetch(`${this.apiBaseUrl}/messages/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ content }),
        credentials: 'include',
      });
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      await this.fetchMessages();
      this.error = '';
    } catch (error) {
      console.error('Error updating message:', error);
      this.error = `Failed to update message: ${error.message}`;
    }
  }

  async deleteMessage(id) {
    try {
      const response = await fetch(`${this.apiBaseUrl}/messages/${id}`, {
        method: 'DELETE',
        credentials: 'include',
      });
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      await this.fetchMessages();
      this.error = '';
    } catch (error) {
      console.error('Error deleting message:', error);
      this.error = `Failed to delete message: ${error.message}`;
    }
  }

  render() {
    if (this.isLoading) {
      return html`<div class="loading">Loading...</div>`;
    }

    return html`
      <div class="container">
        <h1>ProformancePulse Messages</h1>
        ${this.user 
            ? html`
                <div class="user-info">
                  <p>Welcome, ${this.user.name} (${this.user.email})!</p>
                  <button class="logout-button" @click=${this.logout} ?disabled=${this.isLoggingOut}>
                    ${this.isLoggingOut ? 'Logging out...' : 'Logout'}
                  </button>
                </div>
              `
            : html`<button class="login-button" @click=${this.login}>Login</button>`
          }
        ${this.error ? html`<div class="error">${this.error}</div>` : ''}
        ${this.user
          ? html`
              <div class="input-group">
                <input id="newMessage" type="text" placeholder="Enter a new message">
                <button class="add-button" @click=${this._handleAddMessage}>Add Message</button>
              </div>
              <ul>
                ${this.messages.map(
                  (message) => html`
                    <li>
                      <span class="message-content">${message.content}</span>
                      <div class="button-group">
                        <button class="update-button" @click=${() => this._handleUpdateMessage(message.id)}>Update</button>
                        <button class="delete-button" @click=${() => this.deleteMessage(message.id)}>Delete</button>
                      </div>
                    </li>
                  `
                )}
              </ul>
            `
          : html`<p>Please log in to view and manage messages.</p>`
        }
      </div>
    `;
  }


  _handleAddMessage() {
    const input = this.shadowRoot.getElementById('newMessage');
    if (input.value) {
      this.addMessage(input.value);
      input.value = '';
    }
  }

  _handleUpdateMessage(id) {
    const newContent = prompt('Enter new message content:');
    if (newContent !== null) {
      this.updateMessage(id, newContent);
    }
  }
}

customElements.define('proformance-app', ProformanceApp);