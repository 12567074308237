export class AuthService {
    constructor() {
      this.apiBaseUrl = import.meta.env.VITE_API_BASE_URL;
    }
  
    async checkAuthStatus() {
      try {
        const response = await fetch(`${this.apiBaseUrl}/auth/check_session`, { credentials: 'include' });
        if (response.ok) {
          const data = await response.json();
          return data;
        }
      } catch (error) {
        console.error('Error checking auth status:', error);
      }
      return { authenticated: false };
    }
  
    login() {
      window.location.href = `${this.apiBaseUrl}/auth/login`;
    }
  
    async logout() {
      try {
        const response = await fetch(`${this.apiBaseUrl}/auth/logout`, {
          method: 'GET',
          credentials: 'include'
        });
  
        if (response.ok) {
          const data = await response.json();
          window.location.href = data.logoutUrl;
        } else {
          throw new Error('Logout failed');
        }
      } catch (error) {
        console.error('Error logging out:', error);
        throw error;
      }
    }
  }